<template>
  <validation-observer ref="formObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Dane przedstawiciela</h4>

        <b-form-group label="Imię" label-for="v-name">
          <validation-provider name="Imię" rules="required|min:2|max:36" v-slot="{ errors }">
            <b-form-input id="v-name" placeholder="Imię" v-model="form.name" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Nazwisko" label-for="v-surname">
          <validation-provider name="Nazwisko" rules="required|min:2|max:36" v-slot="{ errors }">
            <b-form-input id="v-surname" placeholder="Nazwisko" v-model="form.surname" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Nr telefonu" label-for="v-phone">
          <validation-provider name="Nr telefonu" rules="required|max:15|phone" v-slot="{ errors }">
            <b-form-input id="v-phone" placeholder="Nr telefonu" v-model="form.phone" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Email" label-for="v-email">
          <validation-provider name="Email" rules="required|email" v-slot="{ errors }">
            <b-form-input id="v-email" placeholder="Email" v-model="form.email" type="email" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Województwo" label-for="v-language-select">
          <validation-provider name="Województwo" rules="required" v-slot="{ errors }">
            <v-select
              id="v-language-select"
              label="name"
              :clearable="false"
              placeholder="Wybierz województwo"
              v-model="form.county_id"
              :options="counties" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- TODO: Removed for client tests -->
        <!-- <b-form-group label="Miasto" label-for="v-city">
          <validation-provider name="Miasto" rules="required" v-slot="{ errors }">
            <b-form-input id="v-city" placeholder="Miasto" v-model="form.city" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Kod pocztowy" label-for="v-postal_code">
          <validation-provider name="Kod pocztowy" rules="required|max:10" v-slot="{ errors }">
            <b-form-input id="v-postal_code" placeholder="Kod pocztowy" v-model="form.postal_code" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';
import { all } from '@/http/counties';
import { all as allCountiesEnum } from '@/constants/enums/counties';

export default {
  mixins: [NotificationMixin],
  props: {
    saleRepresentative: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.saleRepresentative,
      counties: [],
    };
  },

  watch: {
    saleRepresentative(newValue) {
      if (!newValue) return;

      this.form = {
        ...newValue,
        county_id: {
          id: newValue.county_id.id,
          name: allCountiesEnum().find((item) => item.key === newValue.county_id.name).value,
        },
      };
    },
  },

  async created() {
    try {
      const { data } = await all();

      this.counties = data.data.map((county) => {
        return {
          ...county,
          name: allCountiesEnum().find((item) => item.key === county.name).value,
        };
      });
    } catch (error) {
      this.showErrorNotification(
        'Problem z zapisaniem danych',
        'Wystąpił problem z pobraniem województw. Skontaktuj się ze swoim developerem.',
      );
    }
  },

  methods: {
    onSubmit() {
      this.$emit('onSubmit', { ...this.form, county_id: this.form.county_id.id });
    },
    validationForm() {
      this.$refs.formObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
